import Vue from 'vue'
import Vuex from 'vuex'
import redis from '@/redis'
import VueCookies from 'vue-cookies'
import _ from 'lodash'

Vue.use(Vuex)
Vue.use(VueCookies)

const getItensCarrinho = async(token) => {  
    let json = await redis.get(token)
    return json
};

const gerarGUID = () => {
    return Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
}

const gerarTokenCarrinho = async(deletar) => {
    let token = ''

    if (deletar){
        await Vue.$cookies.remove('cart-token')
    }

    if (Vue.$cookies.isKey('cart-token')){
        token = await Vue.$cookies.get('cart-token')
    }else{
        token = gerarGUID() + '_cart'
        Vue.$cookies.set('cart-token', token, '2h')
    }

    return token
}

gerarTokenCarrinho(false)

export default new Vuex.Store({
    state: {
        carrinho:{
            itens: [],
            loading : false,
            token: '',
            valorTotal: 0,
            frete: 0,
            tipoEntrega: '',
            valorTotalPedido: 0,
            pedidoFinalizado: false
        },

        cadastro: {
            nome: '',
            email: '',
            celular: '',
        },

        entrega: {
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
        },
    },

    mutations: {
        adicionarItemCarrinho(state, item){
            state.carrinho.itens.push(item)
        },

        carregarCarrinho(state, item){
            state.carrinho.itens = item

            let valor = 0

            _.forEach(item, (value) => {
                valor += value.qtde * value.valor
            });

            state.carrinho.valorTotal = valor
        },

        limparDados(state){
            state.cadastro.nome = ''
            state.cadastro.email = ''
            state.cadastro.celular = ''
            state.entrega.cep = ''
            state.entrega.endereco = ''
            state.entrega.numero = ''
            state.entrega.complemento = ''
            state.entrega.bairro = ''
            state.entrega.cidade = ''
            state.entrega.estado = ''
            state.carrinho.tipoEntrega = ''
            state.carrinho.frete = 0
        },

        alterarLoading(state, load){
            state.carrinho.loading = load
        },

        adicionarFrete(state, valor){
            state.carrinho.frete = valor
        },

        lerAlterarTokenCarrinho(state, token){
            state.carrinho.token = token
        },

        salvarDadosUsuario(state, dados){
            state.carrinho.tipoEntrega = dados.tipoEntrega,
            state.cadastro = dados.cadastro,
            state.entrega = dados.entrega
        },

        finalizarPedido(state, finalizado){
            state.carrinho.pedidoFinalizado = finalizado
        },

        calcularTotalPedido(state){
            let total = parseFloat(state.carrinho.valorTotal) + parseFloat(state.carrinho.frete)
            state.carrinho.valorTotalPedido = total
        }
    },

    getters: {
        itensCarrinho(state){
            return state.carrinho.itens
        },

        valorTotal(state){
            return state.carrinho.valorTotal 
        },

        valorFrete(state){
            return state.carrinho.frete
        },

        valorTotalPedido(state){
            return state.carrinho.valorTotalPedido
        },

        obterDadosUsuario(state){
            let dados = {tipoEntrega: state.carrinho.tipoEntrega, cadastro: state.cadastro, entrega: state.entrega}
            return dados
        },

        pedidoFinalizado(state){
            return state.carrinho.pedidoFinalizado
        },

        tokenCarrinho(state){
            return state.carrinho.token
        }
    },

    actions: {
        async adicionarItemCarrinho({commit, state}, item){
            let token = await gerarTokenCarrinho(false)
            commit('adicionarItemCarrinho', item)
            await redis.set(token, state.carrinho.itens)
            commit('lerAlterarTokenCarrinho', token)
        },

        async removerItemCarrinho({commit, state}, id){
            commit('alterarLoading', true)
            let itens = state.carrinho.itens
            _.remove(itens, (item) => {
                return item.id == id
            })
            let token = await gerarTokenCarrinho(false)
            await redis.set(token, itens)

            let json = await getItensCarrinho(token)
            commit('carregarCarrinho', json)
            commit('alterarLoading', false)
        },

        async limparCarrinho({commit}){
            commit('alterarLoading', true)
            let token = await gerarTokenCarrinho(true)
            await redis.set(token, [])
            commit('carregarCarrinho', [])
            commit('alterarLoading', false)
        },

        async alterarItemCarrinho({commit, state}, itemCarrinho){
            commit('alterarLoading', true)
            let itens = state.carrinho.itens
            _.forEach(itens, (item) => {
                if (itemCarrinho.id == item.id){
                    item.qtde = itemCarrinho.qtde
                }
            })
            let token = await gerarTokenCarrinho(false)
            await redis.set(token, itens)

            let json = await getItensCarrinho(token)
            commit('carregarCarrinho', json)
            commit('alterarLoading', false)
        },

        async carregarCarrinho({commit}){
            commit('alterarLoading', true)

            let token = await gerarTokenCarrinho(false)

            let json = await getItensCarrinho(token)
            if (json){
                commit('carregarCarrinho', json)
            }else{
                commit('carregarCarrinho', [])
            }

            commit('alterarLoading', false)
        },

        adicionarFrete({commit}, valor){
            commit('adicionarFrete', valor)
        },

        salvarDadosUsuario({commit}, valor){
            commit('salvarDadosUsuario', valor)
        },

        calcularTotalPedido({commit}){
            commit('calcularTotalPedido')
        },

        async limparDados({commit, state}){
            await redis.del(state.carrinho.token)
            commit('limparDados')
        },

        finalizarPedido({commit}, finalizado){
            commit('finalizarPedido', finalizado)
        },  

        async lerAlterarTokenCarrinho({commit}, token){
            commit('lerAlterarTokenCarrinho', token)
        }
    },

})
