export default {
    filters: {
        formatarMoedaBR: (value) => {
            let valor = value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
            return valor;
        },
    }
};
