import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
import store from '@/store'

Vue.use(VueRouter)

Vue.prototype.$titulo = 'Bia Truffas e Doces'
Vue.prototype.$notFound = 'Ops!, Não Encontrado'

const DEFAULT_TITLE = Vue.prototype.$titulo

const isTemItensCarrinho = async() => {  
    await store.dispatch('carregarCarrinho')
    return store.getters.itensCarrinho.length > 0
};

const routes = [{
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Home'
    }
  },
  {
    path: '/contato',
    name: 'Contato',
    components: {
      default: () => import('../views/Contato.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Contato'
    }
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    components: {
      default: () => import('../views/Cadastro.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Cadastro'
    }
  },
  {
    path: '/pagamento',
    name: 'Pagamento',
    components: {
      default: () => import('../views/Pagamento.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Pagamento'
    }
  },
  {
    path: '/sobre',
    name: 'Sobre',
    components: {
      default: () => import('../views/Sobre.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Sobre'
    }
  },
  {
    path: '/produtos',
    name: 'Produtos',
    components: {
      default: () => import('../views/Produtos.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Produtos'
    },
    children: [
        {
            path: '/produtos',
            name: 'ProdutoLista',
            component:() => import('../layout/ProdutoLista.vue'),
            meta: {
                title: 'Produtos'
            },
        },
        {
            path: ':id/:categoria/:produto',
            name: 'ProdutoDetalhe',
            component:() => import('../layout/ProdutoDetalhe.vue'),
            meta: {
                title: 'Carregando ...'
            },
        }
    ],
  },
  {
    path: '/encomenda-festa',
    name: 'EncomendaFesta',
    components: {
      default: () => import('../views/EncomendaFesta.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Encomenda Festa'
    }
  },
  {
    path: '/encomenda-corporativo',
    name: 'EncomendaCorporativo',
    components: {
      default: () => import('../views/EncomendaCorporativo.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Encomenda Corporativo'
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    components: {
      default: () => import('../views/Faq.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'F.A.Q'
    }
  },
  {
    path: '/meus-pedidos',
    name: 'MeusPedidos',
    components: {
      default: () => import('../views/MeusPedidos.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Meus Pedidos'
    }
  },
  {
    path: '/carrinho',
    name: 'Carrinho',
    components: {
      default: () => import('../views/Carrinho.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: 'Carrinho'
    }
  },
  {
    path: '*',
    name: 'NotFound',
    components: {
      default: () => import('../views/NotFound.vue'),
      header: Header,
      footer: Footer
    },
    meta: {
        title: Vue.prototype.$notFound
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title  + ' | ' + DEFAULT_TITLE || DEFAULT_TITLE;
    })
})

router.beforeEach( async (to, from, next) => {
    if (to.name == 'Cadastro' || to.name == 'Pagamento'){
        if (await isTemItensCarrinho()){
            next()
        }else{
            next('/carrinho')
        }
    }else{
        next()
    }

    if (to.name == 'Pagamento'){
         if (store.getters.pedidoFinalizado){
             next()
         }else{
             next('/carrinho')
         }
    }

})

export default router