<template>
    <div class="card-insta">
        <div class="container" v-if="!loading">
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <h5>
                        <a target="_blank" :href="urlInsta">@{{ userName }}</a>
                    </h5>
                </div>

                <div
                    v-for="(item, index) in postsInstagram"
                    :key="index"
                    class="col-12 col-md-4 col-lg-3 mb-4"
                >
                    <div class="card p-0">
                        <div>
                            <a :href="item.post_link" target="_blank"
                                ><img
                                    :src="item.url_midia"
                                    class="card-img-top"
                                    alt=""
                            /></a>
                            <vue-show-more-text
                                :text="item.text"
                                :lines="1"
                                more-text="Ver mais"
                                less-text="Ver menos"
                                additional-container-css="margin:0px;"
                                additional-content-css="font-size:16px;"
                                additional-content-expanded-css="font-size:16px; "
                                additional-anchor-css="font-size: 16px; color: #fff;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
            <div
                class="spinner-grow text-light"
                style="width: 3rem; height: 3rem"
                role="status"
            >
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import vueShowMoreText from "vue-show-more-text";
import axiosApi from '@/axios'


export default {
    name: "InstagramFeed",
    components: {
        vueShowMoreText,
    },
    data() {
        return {
            postsInstagram: [],
            userName: "",
            urlInsta: "",
            loading: false
        };
    },

    created() {},

    mounted() {
        this.loading = true
        axiosApi
            .get('https://api.biatruffasedoces.com.br/instagram')
            .then((response) => {
                var posts = response.data.posts;

                if (this.limitMax > 0) {
                    this.postsInstagram = posts.slice(0, this.limitMax);
                } else {
                    this.postsInstagram = posts;
                }

                this.userName = 'biatruffasedoces';
                this.urlInsta = `https://www.instagram.com/${this.userName}/?hl=pt-br`;
                this.loading = false
            });
    },

    props: {
        token: String,
        limitMax: Number,
    },
};
</script>


<style lang="scss">
@import "@/assets/scss/style.scss";
.card-insta {
    padding: 60px 0;
    h5 {
        a {
            color: $marrom;
            font-family: $roboto;
            font-weight: bold;
            font-style: normal;
            font-size: 26px;
            line-height: 26px;
            padding-bottom: 15px;
            text-decoration: none;
        }
    }

    .card {
        background-color: $marrom;
        color: $branco;
    }
}
</style>
  