import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
})

export default axiosApi