<template>
    <div v-if="total > 0"
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
    >
        <div class="carousel-inner">
            <div v-for="(item, index) in sliders" :key="item.id" class="carousel-item" :class="{'active' : index == 0}">
                <img
                    :src="item.url_slider"
                    class="d-block w-100"
                    alt=""
                />
            </div>
        </div>
        <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
        >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
        >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script>
import axiosApi from '@/axios'

export default {

    data(){
        return{
            total: 0,
            sliders: []
        }
    },

    mounted(){
        axiosApi
            .get('/slider')
            .then((response) => {
                this.sliders = response.data.itens
                this.total = this.sliders.length
            });
    }

};
</script>

<style>
</style>