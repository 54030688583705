<template>
    <div class="home">
        <Banner />
        <InstagramFeed :limit-max="12" token="IGQVJYWGU0M2gzc0s3SU5XNFJkTTVlM2h4TjJya1BKdGoxY29idWs1MVRVZAFhNVmx3VmViOWQyRTNTRlM2YWY2SnZATNU5jUW4tN3A0MENTV0Y1aExONWJUV2J4WjFoNTVhSlViRGVKalE0NDl0alNxMgZDZD" />
    </div>
</template>

<script>
import Banner from "@/layout/Banner.vue";
import InstagramFeed from "@/components/InstagramFeed.vue";
export default {
    components: {InstagramFeed, Banner },
    name: "Home",
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.home {
    background-color: $amarelo;
    padding-top: 60px;
}
@media (max-width: 767.98px) {
    .home {
        padding-top: 50px;
    }
}
</style>
