<template>
    <footer>
        <div class="container">
            <div class="row row-cols-5 py-5">
                <div class="col-12 col-md-4">
                    <router-link
                        :to="{ name: 'Home' }"
                        class="
                            d-flex
                            align-items-center
                            mb-3
                            text-decoration-none
                            logo
                        "
                    >
                        <img
                            src="@/assets/img/logo.png"
                            width="190"
                            alt="logo"
                        />
                    </router-link>
                    <p class="">© Bia Truffas e Doces 2022</p>
                </div>

                <div class="col-12 col-md-3 col-xl-2 box">
                    <h5>Empresa</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'Home' }"
                                >Home</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'ProdutoLista' }"
                                >Produtos</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'Sobre' }"
                                >Sobre</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'Contato' }"
                                >Contato</router-link
                            >
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-3 col-xl-2 box">
                    <h5>Encomenda</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'EncomendaFesta' }"
                                >Festas</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'EncomendaCorporativo' }"
                                >Corporativo</router-link
                            >
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-md-2 box">
                    <h5>Pedidos</h5>
                    <ul class="nav flex-column">
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'MeusPedidos' }"
                                >Meus Pedidos</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'Carrinho' }"
                                >Carrinho</router-link
                            >
                        </li>
                        <li class="nav-item mb-2">
                            <router-link
                                class="nav-link p-0"
                                :to="{ name: 'Faq' }"
                                >FAQ</router-link
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <a class="btn-whatsapp" target="_black" href="https://api.whatsapp.com/send?phone=5511981954277&text=Ol%C3%A1%2C%20gostaria%20de%20fazer%20um%20pedido"
            ><img src="@/assets/img/whatsapp.png" alt=""
        /></a>
    </footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>


<style lang="scss">
@import "@/assets/scss/style.scss";
footer {
    background-color: $marrom;
    color: $bege;
    .btn-whatsapp {
        position: fixed;
        bottom: 20px;
        right: 20px;
    }
    h5 {
        font-family: $roboto;
        font-weight: 900;
        font-style: normal;
        font-size: 25px;
        line-height: 35px;
    }
    .nav-link {
        color: $amarelo;
        font-family: $roboto;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        line-height: 30px;
        &:hover,
        &:focus {
            color: $amarelo;
        }
    }
}
@media (max-width: 767.98px) {
    footer {
        text-align: center;
        .logo {
            justify-content: center;
        }
        .box {
            margin-top: 15px;
        }
    }
}
</style>