<template>
    <nav class="navbar navbar-expand-md menu">
        <div class="container-fluid">
            <div class="navbar-brand">
                <router-link
                    :to="{ name: 'Home' }"
                    class="
                        d-flex d-md-none
                        align-items-center
                        mb-2 mb-lg-0
                        text-decoration-none
                    "
                >
                    <img
                        src="@/assets/img/logo-icone.png"
                        width="40"
                        height="32"
                        alt="logo"
                    />
                </router-link>
            </div>
            <button
                class="navbar-toggler hamburger hamburger--elastic-r"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#menuCenter"
                aria-controls="menuCenter"
                aria-expanded="false"
                aria-label="Toggle navigation"
                id="btn-hamburger"
            >
                <div class="hamburger-box">
                    <div class="hamburger-inner"></div>
                </div>
            </button>
            <div class="container">
                <div
                    class="collapse navbar-collapse justify-content-md-between"
                    id="menuCenter"
                >
                    <ul class="navbar-nav">
                        <router-link
                            :to="{ name: 'Home' }"
                            class="
                                d-none d-md-flex
                                align-items-center
                                mb-2 mb-lg-0
                                text-decoration-none
                            "
                        >
                            <img
                                src="@/assets/img/logo-icone.png"
                                width="40"
                                height="32"
                                alt="logo"
                            />
                        </router-link>
                        <li class="nav-item">
                            <router-link
                                class="nav-link px-2"
                                :to="{ name: 'Home' }"
                                active-class="active"
                                exact
                                >Home</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                class="nav-link px-2"
                                :to="{ name: 'ProdutoLista' }"
                                active-class="active"
                                exact
                                >Produtos</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                class="nav-link px-2"
                                :to="{ name: 'Sobre' }"
                                active-class="active"
                                exact
                                >Sobre</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                class="nav-link px-2"
                                :to="{ name: 'Contato' }"
                                active-class="active"
                                exact
                                >Contato</router-link
                            >
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <div class="btn-group px-2">
                            <button
                                type="button"
                                class="btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Encomenda
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'EncomendaFesta' }"
                                        >Festas</router-link
                                    >
                                </li>
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'EncomendaCorporativo' }"
                                        >Corporativo</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="btn-group px-2">
                            <button
                                type="button"
                                class="btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Pedidos
                            </button>
                            <ul class="dropdown-menu">
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'MeusPedidos' }"
                                        >Meus Pedidos</router-link
                                    >
                                </li>
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'Carrinho' }"
                                        >Carrinho</router-link
                                    >
                                </li>
                                <li>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'Faq' }"
                                        >FAQ</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
        <img
            class="img-fluid mx-auto d-none d-md-block bottom-menu"
            src="@/assets/img/bottom-menu.png"
            alt=""
        />
    </nav>
</template>

<script>
export default {
    name: "Header",
    mounted() {
        var largura =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        if (largura <= 767) {
            var descendentes = document.querySelectorAll("#menuCenter a");
            for (var i = 0; i < descendentes.length; i++) {
                descendentes[i].addEventListener("click", function () {
                    document.getElementById("btn-hamburger").click();
                });
            }
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.menu {
    background-color: $marrom;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    padding: 13px 0px;

    .bottom-menu {
        position: absolute;
        top: 56px;
        left: 0;
        z-index: 1;
    }
    .nav-link,
    .dropdown-toggle {
        color: $amarelo;
        font-family: $roboto;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
        padding: 7px 16px !important;
        &:hover,
        &:focus {
            color: $amarelo;
            box-shadow: 0 0 0 0 !important;
        }
    }
}
@media (max-width: 767.98px) {
    .menu {
        padding: 0px 0px;
        .btn-group > .btn {
            flex: 0;
        }
        .btn-group {
            padding: 0 !important;
        }
        .nav-link,
        .dropdown-toggle {
            padding: 16px 0px !important;
        }
        .navbar-toggler:focus {
            box-shadow: 0 0 0;
        }
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .menu {
        padding: 8px 0px;
        .nav-link,
        .dropdown-toggle {
            padding: 12px 16px !important;
        }
    }
}
</style>